export default [
  {
    id: 1,
    name: 'Pierre',
    alt: 'Meet Pierre Backend Developer at Vadis Technologies',
    video: 'https://youtube.com/embed/1B0JU32uZew?si=Fbqg3C9gJzpOjzP3',
  },
  {
    id: 2,
    name: 'Belen',
    alt: 'Meet Pierre Frontend Developer at Vadis Technologies',
    video: 'https://youtube.com/embed/VKN-K_lbE0E?si=MkscpLB7s8S8la7F',
  },
  {
    id: 3,
    name: 'Stephane',
    alt: 'Meet Stepahne DevOps Engineer at Vadis Technologies',
    video: 'https://youtube.com/embed/fGutdmKHLLE?si=L9UWFlxOAYdyNgu0',
  },
  {
    id: 4,
    name: 'Quentin',
    alt: 'Meet Quentin Business Analyst at Vadis Technologies',
    video: 'https://www.youtube.com/embed/axmICa-cLDU?si=dG71geLeUeZAkJRQ',
  },
  {
    id: 5,
    name: 'Sanjina',
    alt: 'Meet Sanjina Data Engineer at Vadis Technologies',
    video: 'https://www.youtube.com/embed/tf4upIFE_jU?si=TXF2EB1zdeIUSIiI',
  },
  {
    id: 6,
    name: 'Sebastien',
    alt: 'Meet Sébastien Project Manager at Vadis Technologies',
    video: 'https://www.youtube.com/embed/B5qJ-C3frNc?si=Sll4ekFPSoE8M0J6',
  },
  {
    id: 7,
    name: 'Marie',
    alt: 'Meet Marie Product Owner/Full Stack Engineer at Vadis Technologies',
    video: 'https://youtube.com/embed/lwvOH4ubSKg?si=I6g38bz_rt5ieGrx',
  },
  {
    id: 8,
    name: 'Florian',
    alt: 'Meet Florian Data Engineer at Vadis Technologies',
    video: 'https://youtube.com/embed/AWxqxQV6DUY?si=L86aH123bHNysgOd',
  },
  {
    id: 9,
    name: 'Jean François',
    alt: 'Meet Jean François CEO at Vadis Technologies',
    video: 'https://www.youtube.com/embed/9NXyuRxZ67w?si=63gOpyxBKVOyUPNK',
  },
]
