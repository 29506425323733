<template>
  <Cookies />
  <NavBar />
  <div
    ref="intersection-observer"
    class="intersection-observer"
  />
  <main>
    <router-view />
  </main>
  <Footer />
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import Cookies from '@/components/Cookies.vue'

export default {
  components: {
    NavBar,
    Footer,
    Cookies,
  },
  mounted () {
    const intersection = this.$refs['intersection-observer']
    const handler = (entries) => {
      if (entries[0].isIntersecting) {
        document.body.classList.remove('sticky')
      } else {
        document.body.classList.add('sticky')
      }
    }
    const observer = new window.IntersectionObserver(handler)
    observer.observe(intersection)
  },
}
</script>
