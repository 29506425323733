<template>
  <section
    id="section-recent-job-offers"
    class="section-recent-job-offers"
  >
    <div class="container margin-bottom--md">
      <p class="subheading">
        {{ jobOffers.title }}
      </p>
    </div>
    <OffersList
      :max-no-offers="maxNoOffers"
    />
    <div class="container">
      <router-link
        to="/job-offers"
        class="btn btn--full"
      >
        {{ jobOffers.body.button }}
      </router-link>
    </div>
  </section>
</template>

<script>
import OffersList from '@/components/OffersList.vue'
export default {
  components: {
    OffersList,
  },
  props: {
    jobOffers: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      maxNoOffers: 3,
    }
  },
}
</script>
