<template>
  <div class="container flex flex--column margin-bottom--bg">
    <JobOfferCard
      v-for="offer in selectedOffers"
      :key="offer.id"
      :offer="offer"
      @click="actions(offer)"
    />
  </div>
  <JobOfferModal
    v-if="modalIsVisible"
    :section="jobOffers.section"
    :offer="offer"
    @close="hideModal()"
  />
</template>

<script>
import jobOffers from '@/data/vacancies/en.js'
import JobOfferCard from '@/components/JobOfferCard.vue'
import JobOfferModal from '@/components/JobOfferModal.vue'
import modal from '@/mixins/open-modal.js'

export default {
  components: {
    JobOfferModal,
    JobOfferCard,
  },
  mixins: [modal],
  props: {
    maxNoOffers: {
      type: Number,
      required: false,
      default: jobOffers.length,
    },
  },
  data () {
    return {
      jobOffers,
      offer: null,
    }
  },
  computed: {
    offers () {
      return this.jobOffers.vacanciesList
    },
    selectedOffers () {
      const publishedOffers = ['communication', 'seniorDataEngineer', 'dataArchitect', 'dataInternship', 'qaAnalyst', 'spontaneous']
      return this.offers.filter(e => publishedOffers.includes(e.tag))
        .sort((a, b) => publishedOffers.indexOf(a.tag) - publishedOffers.indexOf(b.tag))
        .slice(0, this.maxNoOffers)
    },
  },
  methods: {
    actions (offer) {
      this.showModal()
      this.offer = offer
    },
  },
}
</script>
