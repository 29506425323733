<template>
  <div class="modal-wrapper">
    <div class="modal">
      <button
        class="btn--close-modal"
        @click="closeModal()"
      >
        <i class="icon-cross icon--sm" />
      </button>
      <h3>{{ offer.title }}</h3>
      <div
        v-if="offer.description || offer.tasks"
        class="offer-description margin-bottom--md"
      >
        <h4 v-if="offer.tag!=='spontaneous'">
          {{ section.description }}
        </h4>
        <p
          class="margin-bottom--sm"
          v-html=" offer.description"
        />
        <ul class="list offers-list">
          <li
            v-for="(task, i) in offer.tasks"
            :key="i"
            class="list-item list-item--align-center"
          >
            <i class="dot-primary dot-alignment " />
            <p>{{ task }}</p>
          </li>
        </ul>
      </div>
      <div
        v-if="offer.profile.length !==0"
        class="offer-your-profile margin-bottom--md"
      >
        <h4>{{ section.profile }}</h4>
        <ul class="list offers-list">
          <li
            v-for="(qualifitcation, i) in offer.profile"
            :key="i"
            class="list-item list-item--align-center"
          >
            <i class="dot-primary dot-alignment " />
            <p>{{ qualifitcation }}</p>
          </li>
        </ul>
      </div>
      <div
        v-if="offer.skills.length !==0"
        class="offer-skills margin-bottom--md"
      >
        <h4>{{ section.skills }}</h4>
        <ul class="list offers-list">
          <li
            v-for="(skill, i) in offer.skills"
            :key="i"
            class="list-item list-item--align-center"
          >
            <i class="dot-primary dot-alignment" />
            <p>{{ skill }}</p>
          </li>
        </ul>
      </div>
      <div class="offer-interested">
        <p>
          <span>Interested? Send your CV to:</span>
          <a
            class="modal-link"
            href="mailto:jobs@vadis.com"
          >
            jobs@vadis.com
          </a>
        </p>
      </div>
    </div>
  </div>
  <div
    class="overlay"
    @click="closeModal()"
  />
</template>

<script>
import modalClose from '@/mixins/close-modal.js'
export default {
  mixins: [modalClose],
  props: {
    offer: {
      type: Object,
      required: true,
    },
    section: {
      type: Object,
      required: true,
    },
  },
}

</script>
