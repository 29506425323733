<template>
  <asside
    v-if="!cookiesAccepted"
    class="vadis-cookies"
  >
    <p>
      We use cookies to improve your experience on our site. To find out more, read our updated
      <router-link
        to="/privacy-policy"
      >
        Privacy policy
      </router-link>
    </p>
    <button
      class="btn btn--full"
      @click="acceptCookie()"
    >
      <i class="icon-checkmark" /> OK
    </button>
  </asside>
</template>

<script>
import nav from '@/data/nav/en.js'

export default {
  data () {
    return {
      nav,
    }
  },
  computed: {
    cookiesAccepted () {
      console.log('cookies', localStorage.getItem('vadis-cookies-accepted'))
      return localStorage.getItem('vadis-cookies-accepted')
    },
  },
  methods: {
    acceptCookie () {
      localStorage.setItem('vadis-cookies-accepted', true)
      window.location.reload()
    },
  },
}
</script>

<style scoped lang="scss">
.vadis-cookies {
  position: fixed;
  background-color: white;
  color: #5e5a55;
  border-bottom: 1px solid #f19058;
  width: 100%;
  z-index: 50;
  padding: 3.4rem;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  a {
    color: #ed742e;
  }
  button {
    color: white;
    background-color: #ed742e;
    i {
      font-size: 24px;
    }
  }
}
</style>
