export default {
  section: {
    description: 'What you will do ?',
    profile: 'Your profile',
    skills: 'Your skills',
  },
  vacanciesList: [
    {
      id: 1,
      title: 'Senior Data Engineer',
      description: '',
      tasks: [
        'Develop ETL/ELT pipeline - import, mapping, cleaning, standardization, enrichment, export (Talend, AMADEA, spark JAVA, Atlas, Avro);',
        'Design data storage solutions (delta lake, SQL, NoSQL, S3, files);',
        'Automate pipeline monitoring, data quality checks and test cases (Unit);',
        'ETL pipeline optimization (time / resources);',
        'Implement standard data governance and security principles;',
        'CI/CD integration (Gitlab/Airflow/Docker/Nexus);',
        'Cloud deployment (AWS);',
        'Responsible of pipeline results deliveries to end customer.',
      ],
      profile: [
        'Master’s degree in Big Data and Analytics, Statistics, Mathematics, Computer Science, or related field;',
        'Fluent in English (verbal & written). French is a plus;',
        'Having 5 years’ experience in data transformations and analytics projects is an asset;',
        'Eligible to work in Belgium.',
      ],
      skills: [
        'Strong problem solving skills with an emphasis on product development;',
        'Excellent understanding of machine learning techniques and algorithms: Regression, clustering, random forest, text mining, decision trees, neural networks, etc.',
        'Good scripting and programming skills: R, Python, SQL, Java, C/C++, Talend, Spark, etc.',
        'Passionate about data and/or statistical analysis, with mathematics and modelling skills;',
        'Good communication skills - ability to be the bridge between the technical and non-technical elements of the business;',
        'Knowledge in data warehouse and ETL tools is an asset;',
        'Experience with data visualization tools is a plus;',
        'Autonomous, eager to learn;',
        'Able to plan and prioritize its workload alone and with teammate.',
      ],
      details: {
        contract: 'Permanent contract',
        experience: '> 5 years',
        where: 'Brussels',
      },
      tag: 'seniorDataEngineer',
    },
    {
      id: 2,
      title: 'Full stack developer',
      description: 'You will be developing server-side logic, defining and maintaining the structure of our product data sources together with our team&nbsp;of&nbsp;data&nbsp;scientists ; you will ensure high performance and responsiveness to requests from the front-end as well as security and reliability of of&nbsp;the&nbsp;service. You will be in close contact with our front-end team to define the RESTful APIs which will be consumed by our web application but at the same time you will also define and document our SaaS API which will be directly integrated into our customers systems.',
      tasks: [
        'Work together with PO and Front-End to transform business needs into API definition;',
        'Work together with DevOps to ensure CI/CD;',
        'Write clean, top of the class, performant, secure and reusable OO code;',
        'Code with performances in mind to ensure responsiveness, high data throughput and platform scalability;',
        'Follow secure coding best practices;',
        'Attend regular meetings with the Front-End, Data and Business teams;',
        'Document APIs to ease system integration.'],
      profile: [],
      skills: [
        'BS or higher education in Computer Science, Software Engineering, or IT-oriented degree program;',
        '5+ year proven experience in a similar position;',
        'Vast knowledge of C# .NET (with a focus on .NET Core), SQL, RESTful web-services or Ruby;',
        'Practical experience in front-end development with Angularjs / Angular + HTML / CSS / Jacascript / Jquery;',
        'Working knowledge of Python and Java is a plus;',
        'Knowledge of Linux and experience in developing cloud solution is a big plus;',
        'Thorough understanding of application security concepts such as: authentication and authorization mechanisms, data validity, data integrity, data confidentiality, data availability, logging and monitoring at different levels of the stack, and user non-repudiation;',
        'Ability to meet tight deadlines;',
        'Capable of prioritizing multiple projects to meet goals without management oversight;',
        'Team player, at ease in a small but skillful team and a fast-paced working environment;',
        'At ease working with JIRA, Confluence, Jenkins, GIT;',
        'Experience working with agile scrum methodology.'],
      details: {
        contract: 'Permanent contract',
        experience: '> 5 years',
        where: 'Brussels',
      },
      tag: 'fullStackDeveloper',
    },
    {
      id: 3,
      title: 'SysOps',
      description: '',
      tasks: [
        'Identity management and security: Windows Active Directory, LDAP, Linux PAM, SSO (Okta, Keycloak), MFA, vault;',
        'Improve resilience and security by introducing best practices and innovative tools;',
        'Assist project teams in managing their infrastructure;',
        'Manage secrets : domain names, certificates, API keys ...',
        'Assets Management;',
        'Maintain various operating systems of our platforms (Windows, Linux).',
      ],
      profile: [
        'You want to invest in a team and share your skills;',
        'You know how to measure the risk: availability of services VS evolution;',
        'You have a strong ability to learn and communicate (FR & EN);',
        'You have integrity and put your administrator rights at the service of the team;',
        'Open to working with complex and powerful Cloud architectures: high availability, load balancing, caches, security (Anti-DDoS, WAF).',
      ],
      skills: [
        'You can demonstrate a long experience in user management and the definition of security policies adapted to the development world;',
        'You have extensive knowledge of networking ( TCP/IP, subnet, routing, firewall, VPN, DNS, ...);',
        'You have a solid knowledge of Windows & Linux administration;',
        'The different types of storage have no secret for you;',
        'Fluent in French and English;',
        'A first experience with Cloud Native tools (Docker, Kubernetes,...);',
        'You have knowledge in automation (e.g. Ansible, Python or Bash scripting, GitLab CI, Jenkins, Terraform);',
        'DB & data tools administration (MSSQL, MYSQL, PostgreSQL, Redis, ElasticSearch);',
        'A first experience with AWS or OVH.',
      ],
      details: {
        contract: 'Permanent contract',
        experience: '> 3 years',
        where: 'Brussels',
      },
      tag: 'sysOps',
    },
    {
      id: 4,
      title: 'Intership in recruitment and communication',
      description: '',
      tasks: [
        'Manage the recruitment process from the creation of the job description to the interview and then manage the onboarding of new hires. You will also need to source candidates on LinkedIn (send messages to good profiles);',
        'Follow up with coworkers to help them set objectives and understand their needs in terms of training, for example;',
        'Provide support for marketing, including creating communication materials and advertising campaigns on different social media platforms (LinkedIn, Facebook…);',
        'Organize team events every 3 months, including planning, activities, and conferences.',
      ],
      profile: [
        'Proficiency in French and English;',
        'HR master\'s degree ongoing;',
        'Eligible to work in Belgium.',
      ],
      skills: [
        'Be versatile and creative;',
        'Be keen to specialise in the recruitment of technical professions;',
        'Be prepared to challenge yourself by sourcing jobs in short supply;',
        'Excellent command of English and French;',
        'Be methodical, organised and rigorous;',
        'Have an attraction for the human side of Human Resources;',
        'Be a good listener.',
      ],
      details: {
        contract: 'Intership',
        experience: 'Student',
        where: 'Brussels',
      },
      tag: 'communication',
    },
    {
      id: 5,
      title: 'Spontaneous application',
      description: 'Do you want to save the world by detecting frauds? (ethique) Join our dynamic team and help create our innovative solutions. Together, we can shape the financial landscape of tomorrow with your expertise in development and data engineering.',
      tasks: [],
      profile: [],
      skills: [],
      details: {
        contract: '',
        experience: '',
        where: 'Brussels',
      },
      tag: 'spontaneous',
    },
    {
      id: 6,
      title: 'Data Architect / Data Pipeline Builder',
      description: '',
      tasks: [
        'Play a lead role for helping define, implement and evolve key architectural components of the enterprise data lake ecosystem;',
        'Define the modern distributed data lake product architecture leveraging a hybrid cloud environment, distributed data pipelines, and incorporating automated governance processes;',
        'Evaluate current architectures, identifying potential inefficiencies related to cost, maintainability and scalability;',
        'Propose and drive architectural design decisions;',
        'Support our data engineers on data initiatives and ensure optimal data delivery architecture is consistent throughout ongoing projects;',
        'Stay current with server-side and Cloud based trends and architectures;',
        'Develop automated data pipelines using big data tools to support our projects of data entity recognition, graph historization, network analysis, predictive models, etc.',
      ],
      profile: [
        'Master’s degree in Big Data and Analytics, Statistics, Mathematics, Computer Science, or related field;',
        'Fluent in English (verbal & written). French or Dutch is a plus;',
        'Having 7+ years’ experience in big data transformations and integration projects;',
        'Eligible to work in Belgium;',
      ],
      skills: [
        'Expertise in data models, data pipeline concepts, cloud based infrastructure and distributed systems;',
        'Strong experience in big data techniques and best practices to handle large data volume: Spark, Hadoop, Kafka, etc.',
        'Strong experience with SQL and NoSQL databases: SQL server, Postgres, Cassandra, MongoDB;',
        'Proficient in data sourcing, data capture, data transformation and data preparation techniques (mapping, cleansing, standardization, etc.);',
        'Good scripting and programming skills: Java, Scala, Python;',
        'Good communication skills - ability to be the bridge between the technical and non-technical elements of the business;',
        'Confident in decision making and the ability to explain processes or choices as needed;',
        'Must be able to develop creative solutions to problems;',
      ],
      details: {
        contract: 'Permanent contract',
        experience: '> 7 years',
        where: 'Brussels',
      },
      tag: 'dataArchitect',
    },
    {
      id: 7,
      title: 'Data Engineer Internship',
      description: '',
      tasks: [
        'Contribute to our research activities by applying modern data analytics and machine learning on a variety of structured data, to discover insights, causalities, create predictive models, machine-learning algorithms;',
        'Develop automated data preparation processes using tools (Talend, Spark, Knime, Elasticsearch, TigerGraph) to solve challenging problematics such as data entity recognition, network analysis and pattern extraction, features engineer to support positive and negative predictive models;',
        'Translate business problems and potential actions into decision processes based on insights from 360° view datasets or from predictive models built with Python, XGBoost or stacking methodology;',
        'Scan horizon to stay up to date with latest technology, techniques and methods;',
      ],
      profile: [
        'Master’s degree in Big Data and Analytics, Statistics, Mathematics, Computer Science, or related field;',
        'Fluent in English (verbal & written). French or Dutch is a plus;',
        'Having 1-2 years’ experience in data transformations and analytics projects is an asset;',
        'Eligible to work in Belgium;',
      ],
      skills: [
        'Passionate about data and/or statistical analysis, with mathematics and modelling skills;',
        'Strong problem solving skills with an emphasis on product development;',
        'Excellent understanding of machine learning techniques and algorithms: Regression, clustering, random forest, text mining, decision trees, neural networks, etc.',
        'Good scripting and programming skills: R, Python, SQL, Java, C/C++, etc.',
        'Knowledge in data warehouse and ETL tools is an asset;',
      ],
      details: {
        contract: 'Internship',
        experience: 'Student',
        where: 'Brussels',
      },
      tag: 'dataInternship',
    },
    {
      id: 8,
      title: 'Senior Software QA Analyst',
      description: 'You will be responsible for the daily coordination and testing of our various risk management products. In this role, you will work directly with production teams and other stakeholders, including external customers and sponsors.',
      tasks: [
        'You will communicate testing status throughout sprints, as well as provide testing reports according to the specific project or product requirements;',
        'You will implement sprint and/or feature test plans as well as be responsible for smoke testing, functional testing, integration testing, system testing and compatibility testing on the development, release and production servers. These tests will be manual or automated;',
        'You will manage daily tasks and work with other team members on testing scope and risk mitigation for products in development;',
        'You will create and execute test cases against requirements and provide your input throughout the whole product lifecycle;',
        'In addition, you will verify bug fixes and close issues upon resolution as well as execute exploratory, performance, integration and other complex testing tasks;',
        'We expect you to be a part of the whole product lifecycle, since our team is highly cross-functional. Your input will be highly regarded & appreciated;',
        'We will offer support, training and follow-up on your professional development;',
      ],
      profile: [
        'Bachelor’s Degree in Computer Science, Computer Engineering, Information Technology, or a related field (or equivalent experience);',
        '3+ years of relevant work experience;',
        'Knowledge of at least one programming/ scripting language;',
        'Knowledge of at least one database management system;',
        'Understand the software development lifecycle of a product;',
        'Previous experience writing and executing test cases;',
        'Previous experience preparing & presenting test reports;',
      ],
      skills: [
        'Experience with one test automation framework : Selenium, Serenity, Cypress,…',
        'Experience with one CI tool : Jenkins, Gitlab, Bamboo,…',
        'Affinity with BDD approach (Gherkin language).',
        'Some related certification : iSTQB , CAST, Scrum,…',
        'Knowledge and experience working with JIRA, Confluence, Slack, GitHub, …',
        'Experience working closely with build and release teams including use of version control systems.',
        'Openness, curiosity and willingness to learn.',
      ],
      details: {
        contract: 'Permanent contract',
        experience: '> 3 years',
        where: 'Brussels',
      },
      tag: 'qaAnalyst',
    },
  ],
}
