<template>
  <div class="modal-wrapper">
    <div class="modal">
      <button
        class="btn--close-modal"
        @click="closeModal()"
      >
        <i class="icon-cross icon--sm" />
      </button>
      <iframe
        width="100%"
        height="100%"
        :src="src"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </div>
  </div>
  <div
    class="overlay"
    @click="closeModal()"
  />
</template>

<script>
import modalClose from '@/mixins/close-modal.js'

export default {
  mixins: [modalClose],
  props: {
    src: {
      type: String,
      required: true,
    },
  },
}
</script>
