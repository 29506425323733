<template>
  <div class="section-privacy-policy">
    <h1>Privacy Policy</h1>
    <div
      v-for="(content) in privacyPolicy.content"
      :key="content.id"
    >
      <h2>{{ content.title }}</h2>
      <p
        v-for="(text, i) in content.description"
        :key="i"
        v-html="text"
      />
    </div>
  </div>
</template>

<script>
import privacyPolicy from '@/data/privacy-policy/en.js'

export default {
  data () {
    return {
      privacyPolicy,
    }
  },
}
</script>
