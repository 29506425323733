<template>
  <section class="section-all-videos">
    <div class="container">
      <button
        class="btn btn--full"
        @click="actions()"
      >
        See more videos
      </button>
    </div>
    <AllVideosModal
      v-if="modalIsVisible"
      :videos="videos"
      @close="hideModal()"
    />
  </section>
</template>

<script>
import modal from '@/mixins/open-modal.js'
import AllVideosModal from '@/components/AllVideosModal.vue'

export default {
  components: {
    AllVideosModal,
  },
  mixins: [modal],
  props: {
    videos: {
      type: Object,
      required: true,
    },
  },
  methods: {
    actions () {
      this.showModal()
    },
  },
}
</script>
