<template>
  <section class="section-testimonials">
    <div class="container margin-bottom--md">
      <p class="subheading">
        Happy employees
      </p>
    </div>
    <div class="container grid grid--2-cols">
      <figure
        v-for="testimonial in testimonials"
        :key="testimonial"
        class="testimonial flex flex--column"
      >
        <i class="icon-quotes-left testimonial-icon" />
        <blockquote class="testimonial-text">
          <p v-html="testimonial.text " />
        </blockquote>
        <figcaption
          class="testimonial-employee-box flex flex--row"
          :class="testimonial.class"
        >
          <img
            :src="require(`../../assets/pictures/${testimonial.img}`)"
            :alt="testimonial.alt"
            class="testimonial-img"
          >
          <div class="testimonial-employe-data">
            <p class="employee-name">
              {{ testimonial.who }}
            </p>
            <p class="employee-job">
              {{ testimonial.role }}
            </p>
          </div>
        </figcaption>
      </figure>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    testimonials: {
      type: Object,
      required: true,
    },
  },
}
</script>
