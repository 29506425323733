<template>
  <Hero
    :hero="home.hero"
  />
  <About
    :about="home.about"
  />
  <RecentOffers
    :job-offers="home.recentJobOffers"
  />
  <KeyFigures
    :key-figures="home.keyFigures"
  />
  <OurValues
    :values="home.values"
  />
</template>

<script>
import Hero from '@/components/Home/Hero.vue'
import About from '@/components/Home/About.vue'
import RecentOffers from '@/components/Home/RecentOffers.vue'
import KeyFigures from '@/components/Home/KeyFigures.vue'
import OurValues from '@/components/Home/OurValues.vue'
import home from '@/data/home/en.js'

export default {
  components: {
    Hero,
    About,
    RecentOffers,
    KeyFigures,
    OurValues,
  },
  data () {
    return {
      home,
    }
  },
}
</script>
