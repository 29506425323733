<template>
  <header :class="['header', open? 'nav-open': '']">
    <!-- logo and lang switcher-->
    <div class="flex flex--row">
      <a href="https://www.vadis.com/">
        <img
          class="logo"
          alt="Join Vadis Technologies, a regtech specialist based in Paris and Brussels"
          src="../assets/logo/logo-de-l-entreprise-vadis-technologies.png"
        >
      </a>
    </div>
    <!-- nav -->
    <nav class="main-nav">
      <ul class="main-nav-list">
        <li @click="closeNav()">
          <router-link
            to="/"
            class="main-nav-link"
          >
            {{ nav.home }}
          </router-link>
        </li>
        <li @click="closeNav()">
          <router-link
            to="/tech-projects"
            class="main-nav-link"
          >
            {{ nav.techsProjects }}
          </router-link>
        </li>
        <li @click="closeNav()">
          <router-link
            to="/meet-us"
            class="main-nav-link"
          >
            {{ nav.meetUs }}
          </router-link>
        </li>
        <li @click="closeNav()">
          <router-link
            to="/job-offers"
            class="main-nav-link nav-cta"
          >
            {{ nav.jobOffers }}
          </router-link>
        </li>
      </ul>
    </nav>
    <!-- Mobile nav button -->
    <button
      class="btn-mobile-nav"
      @click="toggleNav()"
    >
      <i class="icon-mobile-nav icon-th-menu" />
      <i class="icon-mobile-nav icon-cross" />
    </button>
  </header>
</template>

<script>
import nav from '@/data/nav/en.js'

export default {
  data () {
    return {
      nav,
      open: false,
    }
  },
  methods: {
    toggleNav () {
      this.open = !this.open
    },
    closeNav () {
      this.open = false
    },
  },
}
</script>
