<template>
  <TechsProjects
    :techs-projects="techsProjects"
  />
</template>

<script>
import TechsProjects from '@/components/TechAndProjects/TechsProjects.vue'
import techsProjects from '@/data/tech-projects/en.js'

export default {
  components: {
    TechsProjects,
  },
  data () {
    return {
      techsProjects,
    }
  },

}
</script>
