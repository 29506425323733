<template>
  <section class="section-soft-skills">
    <div class="container margin-bottom--md">
      <h3>{{ softSkills.title }}</h3>
    </div>
    <div class="container grid grid--flex-start grid--3-cols">
      <div
        v-for="(block, i) in softSkills.body"
        :key="i"
      >
        <h4>{{ block.title }}</h4>
        <p>{{ block.text }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    softSkills: {
      type: Object,
      required: true,
    },
  },
}
</script>
