export default {
  subheading: 'Techs and projects',
  title: 'Looking for some interesting projects ?',
  button: 'Have a look at our job offers !',
  sections: {
    dataLine: {
      title: 'Production Data Line',
      content:
      [{
        id: 1,
        title: 'Enrichment Moody\'s',
        description: ['We can help you to create and keep one version of truth through data cleansing, de&#x2011;duplication, quality review, enrichment and matching with external databases.',
          'Entity resolution and master data provides the foundation for many key business processes. When this information is not accurate, consistent and up-to-date, organizations face inefficiencies, delays, errors, risks and poor business decisions.',
          'Vadis Technologies works for global data providers to make their B2B data more reliable.'],
        technologies: ['Spark', 'Java', 'GraphX'],
      },
      {
        id: 2,
        title: 'Institutional project',
        description: ['The historic Vadis project is a fraud detection project for a&nbsp;European&nbsp;institution.',
          'It\'s a risk management tool for a&nbsp;European&nbsp;institution to prevent fraud and&nbsp;irregularities.'],
        technologies: ['C#', '.NET', 'Angular JS'],
      },
      ],
    },
    softwareDevelopment: {
      title: 'Back-end/Software Development',
      content:
      [{
        id: 1,
        title: 'Flair Diligence',
        description: ['Flair is a product developed by Vadis Technologies in 2022. Flair aims to provide a platform that promotes transparency in decision-making.',
          'It\'s a 2 in one tool providing the user with a screening module as well as a&nbsp;due&nbsp;diligence module. This enables the user to have a 360 vision on all its third-parties.'],
        technologies: ['Ruby', 'Vue JS', 'Sketch', 'HTML', 'CSS'],
      }, {
        id: 2,
        title: 'Client tools',
        description: ['Vadis collaborates with a specialized recruiting agency to&nbsp;engineer a tool seamlessly integrating Beeple and EasyPay platforms. This innovation streamlines contract workflows, optimizing their creation and&nbsp;management'],
        technologies: ['NET', 'C#', 'SQL serveur', 'Git'],
      }],
    },
    rd: {
      title: 'R&D Projects',
      content:
      [{
        id: 1,
        title: 'UBO',
        description: ['The issue of Ultimate Beneficial Owners (UBO) pertains to a&nbsp;worldwide&nbsp;network of companies and shareholders. By&nbsp;effectively addressing this concern, we can provide complete transparency to our customers about the entities they engage with and the&nbsp;ultimate beneficiaries behind these business dealings.', 'This proactive approach aids our customers and regulators in detecting fraudulent activities like conflicts of interest, terrorism financing, money laundering, or&nbsp;tax evasion (similar to the Panama Papers).', 'Moreover, in many instances, companies are legally obligated to&nbsp;disclose their ultimate beneficial owners to regulators.'],
        technologies: ['Spark Java', 'Atlas', 'Avro', 'Airflow', 'Docker', 'GitLab', 'Neo4J', 'AWS', 'Terraform'],
      },
      {
        id: 2,
        title: 'Data platform',
        description: ['Data heterogeneity poses a significant challenge in the data world. Data schemas evolve, new data sources emerge or disappear, necessitating data processors to continuously adjust their methods. Typically, data providers offer similar entities and characteristics, but they are formatted differently.',
          'As one of Vadis\' objectives is to enhance the initial data, developing separate enrichment processes for each data provider would be inefficient.',
          'To tackle this issue, Vadis aims to become data agnostic and implement a harmonization process, ensuring that all data is standardized. This unified data will then be sent to the Vadis data lake, feeding a single streamlined enrichment process for all data providers.'],
        technologies: ['Spark Java', 'Atlas', 'Avro', 'Airflow', 'Docker', 'GitLab', 'Neo4J', 'AWS', 'Terraform'],
      },
      ],
    },
  },
}
