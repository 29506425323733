<template>
  <div :class="section.class">
    <div class="container">
      <h3>{{ section.content.title }}</h3>
    </div>
    <div class="container grid grid--2-cols grid--flex-stretch">
      <template
        v-for="(project, i) in section.content.content"
        :key="i"
      >
        <div class="flex flex--column">
          <div class="project-text-box">
            <h4>{{ project.title }}</h4>
            <template
              v-for="(parr, i) in project.description"
              :key="i"
            >
              <p v-html="parr" />
            </template>
          </div>
          <TechCard
            :technologies="project.technologies"
            :card-position="section.backgroundColor? 'white' : cardPosition(i+1)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TechCard from '@/components/TechCard.vue'

export default {
  components: {
    TechCard,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  methods: {
    cardPosition (number) {
      if (number % 2 === 0) {
        return 'right'
      } else {
        return 'left'
      }
    },
  },
}
</script>
