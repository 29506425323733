import picsPath from '../gallery.js'
import videos from '../videos.js'

export default {
  gallery: {
    subheading: 'Life at Vadis Technologies',
    title: 'Meet your future colleagues',
    body: {
      imgsInfo: [
        {
          id: 1,
          src: picsPath.find(pic => pic.id === 1).src,
          alt: 'Meet Florian Data Engineer at Vadis Technologies',
          caption: 'Meet Florian (Data Engineer)',
          video: 'https://youtube.com/embed/AWxqxQV6DUY?si=IkbWU2s1gAbnY367',
        },
        {
          id: 2,
          src: picsPath.find(pic => pic.id === 2).src,
          alt: 'Meet the team working on our historical project at Vadis Technologies',
          caption: '',
        },
        {
          id: 3,
          src: picsPath.find(pic => pic.id === 3).src,
          alt: 'Meet Marie Product Owner/Full Stack Engineer at Vadis Technologies',
          caption: 'Meet Marie (Product owner Flair)',
          video: 'https://youtube.com/embed/lwvOH4ubSKg?si=KXJDmJjhXm7KZSfD',
        },
        {
          id: 4,
          src: picsPath.find(pic => pic.id === 4).src,
          alt: 'At Vadis Technologies team work is one of our core values',
          caption: '',
        },
        {
          id: 5,
          src: picsPath.find(pic => pic.id === 5).src,
          alt: 'Meet Sanjina Data Engineer at Vadis Technologies',
          caption: 'Meet Sanjina (Data engineer)',
          video: 'https://www.youtube.com/embed/tf4upIFE_jU?si=qLYBLuK64fn3jYYm',
        },
        {
          id: 6,
          src: picsPath.find(pic => pic.id === 6).src,
          alt: 'Meet Sébastien Project Manager at Vadis Technologies',
          caption: 'Meet Sébastien (Project manager)',
          video: 'https://www.youtube.com/embed/B5qJ-C3frNc?si=S1ILNvjLs5O3ulS5',
        },
        {
          id: 7,
          src: picsPath.find(pic => pic.id === 7).src,
          alt: 'Meet the data team at Vadis Technologies',
          caption: '',
        },
        {
          id: 8,
          src: picsPath.find(pic => pic.id === 8).src,
          alt: 'Meet the women in the team Vadis Technologies',
          caption: '',
        },
        {
          id: 9,
          src: picsPath.find(pic => pic.id === 9).src,
          alt: 'Teambuilding is really important for us at Vadis technologies.',
          caption: '',
        },
        {
          id: 10,
          src: picsPath.find(pic => pic.id === 10).src,
          alt: 'Meet Quentin Business Analyst at Vadis Technologies',
          caption: 'Meet Quentin (Business Analyst)',
          video: 'https://www.youtube.com/embed/axmICa-cLDU?si=UFQMNL7kEvtggGUY',
        },
        {
          id: 11,
          src: picsPath.find(pic => pic.id === 11).src,
          alt: 'Meet the team working on Flair Diligence at Vadis Technologies',
          caption: '',
        },
        {
          id: 12,
          src: picsPath.find(pic => pic.id === 12).src,
          alt: 'At Vadis technologies we organize conferences like here for the Flair Diligence project.',
          caption: '',
        },
      ],
    },
  },
  testimonials: [
    {
      text: 'I enjoy working at Vadis Technologies for a number of reasons, the&nbsp;main&nbsp;one being the independence I enjoy thanks to the trust Jean&#x2011;François and the team place in me. I also really enjoy working in a team with talented people and the fact that I have the opportunity to work on new problems every week as the Flair Diligence product progresses. Finally, I like the fact that the product is part of a growing legal framework and that it has a positive social dimension (with ecovadis scores).',
      who: 'Pierre',
      role: 'Back End Developer',
      img: 'pierre-back-end-developer-vadis-technologies.jpg',
      alt: 'Meet Pierre Back End Developer at Vadis Technologies',
    },
    {
      text: 'Vadis Technologies is a great place to work as it encourages initiatives to discover and improve (new) ways to solve challenging problematics. This contributes to good team dynamic and brainstormings feeding. As a scale-up, people are also allowed to have new roles (if they ask for it) which is important for its personal career path.',
      who: 'Jonathan',
      role: 'Head of Data',
      img: 'jonathan-head-of-data-vadis-technologies.jpg',
      alt: 'Meet Jonathan Head of Data at Vadis Technologies',
      class: 'short',
    },
    {
      text: 'Working at Vadis Technologies means having the chance to grow professionally and personally with a great team and motivating projects.',
      who: 'Cédric',
      role: 'Product Manager',
      img: 'cedric-project-manager-vadis-technologies.jpg',
      alt: 'Meet Cédric Product Manager/Business Analyst  at Vadis Technologies',
      class: 'short',
    },
    {
      text: 'Applying DevOps at Vadis Technologies allows me to actively exercise my Cloud Native skills. In addition to the CSR theme of our Flair application, I have the opportunity to put FinOps into practice by optimizing our cloud infrastructures to provide dynamic performance while maintaining a minimal carbon footprint.',
      who: 'Stéphane',
      role: 'DevOps',
      img: 'stephane-devops-vadis-technologies.jpg',
      alt: 'Meet Stéphane DevOps at Vadis Technologies',
    },
  ],
  allvideos: [...videos],
}
