<template>
  <div class="section-tech-projects">
    <div class="container">
      <p class="subheading">
        {{ techsProjects.subheading }}
      </p>
      <h2>{{ techsProjects.title }}</h2>
    </div>
    <ProjectsSection
      v-for="(section, i) in sections"
      :key="i"
      :section="section"
    />
    <div class="container section-tech-projects-button">
      <router-link
        to="/job-offers"
        class="btn btn--full"
      >
        {{ techsProjects.button }}
      </router-link>
    </div>
  </div>
</template>

<script>
import ProjectsSection from './ProjectsSection.vue'

export default {
  components: {
    ProjectsSection,
  },
  props: {
    techsProjects: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sections () {
      return [
        {
          content: this.techsProjects.sections.dataLine,
          class: 'section-data-line',
          backgroundColor: true,
        },
        {
          content: this.techsProjects.sections.softwareDevelopment,
          class: 'section-software-development',

        },
        {
          content: this.techsProjects.sections.rd,
          class: 'section-rd',
          backgroundColor: true,
        },
      ]
    },
  },
}
</script>
