<template>
  <div :class="['project-card-tech-box', `project-card-tech-box--${cardPosition? cardPosition : 'neutral'}`]">
    <h4>Tech</h4>
    <ul class="list tech-list">
      <li
        v-for="(tech,j) in technologies"
        :key="j"
        class="list-item"
      >
        <p>{{ tech }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    technologies: {
      type: Object,
      required: true,
    },
    cardPosition: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    cardPositionRight () {
      return this.cardPosition === 'right'
    },
    cardPositionLeft () {
      return this.cardPosition === 'left'
    },
    cardPositionNeutral () {
      return this.cardPosition === 'white'
    },
  },
}
</script>
