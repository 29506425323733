<template>
  <section class="section-all-job-offers">
    <div class="container">
      <p class="subheading">
        {{ jobOffers.subheading }}
      </p>
      <h3>{{ jobOffers.title }}</h3>
    </div>
    <OffersList />
  </section>
</template>

<script>
import OffersList from '../OffersList.vue'

export default {
  components: {
    OffersList,
  },
  props: {
    jobOffers: {
      type: Object,
      required: true,
    },
  },
}
</script>
