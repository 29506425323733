export default [
  {
    id: 1,
    src: 'florian-data-engineer-vadis-technologies.jpg',
  },
  {
    id: 2,
    src: 'photo-d-equipe-vadis-technologies.jpg',
  },
  {
    id: 3,
    src: 'marie-product-owner-vadis-technologies.jpg',
  },
  {
    id: 4,
    src: 'le-travail-d-equipe-chez-vadis-technologies.jpg',
  },
  {
    id: 5,
    src: 'sanjina-data-engineer-vadis-technologies.jpg',
  },
  {
    id: 6,
    src: 'sebastien-project-manager-vadis-technologies.jpg',
  },
  {
    id: 7,
    src: 'photo-de-l-equipe-data-vadis-technologies.jpg',
  },
  {
    id: 8,
    src: 'photo-des-femmes-de-vadis-technologies.jpg',
  },
  {
    id: 9,
    src: 'le-teambuilding-chez-vadis-technologies.jpg',
  },
  {
    id: 10,
    src: 'quentin-business-analyst-vadis-technologies.jpg',
  },
  {
    id: 11,
    src: 'photo-de-l-equipe-flair-vadis-technologies.jpg',
  },
  {
    id: 12,
    src: 'une-conference-du-projet-flair-vadis-technologies.jpg',
  },
]
