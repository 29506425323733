export default {
  data () {
    return {
      modalIsVisible: false,
    }
  },
  methods: {
    showModal () {
      this.modalIsVisible = true
    },
    hideModal () {
      this.modalIsVisible = false
    },
  },
}
