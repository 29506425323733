<template>
  <AllOffers
    :job-offers="jobOffers.jobOffers"
  />
  <SoftSkills
    :soft-skills="jobOffers.softSkills"
  />
</template>

<script>
import AllOffers from '@/components/JobOffers/AllOffers.vue'
import SoftSkills from '@/components/JobOffers/SoftSkills.vue'
import jobOffers from '@/data/job-offers/en.js'

export default {
  components: {
    AllOffers,
    SoftSkills,
  },
  data () {
    return {
      jobOffers,
    }
  },

}
</script>
