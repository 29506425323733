export default {
  hero: {
    title: 'Discover Vadis Technologies',
    body: {
      text: 'Join our team and become part of our growing company, where&nbsp;technology and creativity go hand in hand.',
      img: {
        alt: 'Vadis group photo',
      },
      button: 'Discover our job offers',
    },
  },
  about: {
    title: 'Our story',
    body: {
      text: ['Vadis Technologies is a fast-growing regtech, based&nbsp;in&nbsp;Paris and Brussels. We are part of the Intys Partners group since 2018. We focus our work on data production lines and backend / software development.',
        'Before you make a deal or when you’re establishing business relations, you need to know who you’re dealing with. That’s TPRM or KYB and&nbsp;the&nbsp;purpose of&nbsp;Vadis&nbsp;Technologies. We provide transparency when trust is unreliable.',
        'Our solutions aggregate, calculate and enrich public and private data. This enables our clients to gain a clearer comprehension of their&nbsp;third&#x2011;party&nbsp;entities. It can be either suppliers, clients, or partners. Then our users can manage the associated risk, make informed decisions, and&nbsp;comply&nbsp;with&nbsp;regulations.'],
      quote: 'We feel useful to society and involved in making it better and fairer. Everything we do is closely linked to current events.',
      img: {
        alt: 'Jean-François Heering, CEO of Vadis technologies, a regtech scale-up specialized in TPRM and data management',
      },
      cta: {
        text: 'Ready to join our team ?',
        link: 'Let\'s meet',
      },
    },
  },
  recentJobOffers: {
    title: 'Our job vacancies',
    body: {
      button: 'See more',
    },
  },
  keyFigures: {
    title: 'Key numbers',
    body: {
      text: {
        employeesNo: '23 employees',
        genderEquality: '22% women <br/> 78% men',
        averageAge: '32',
        nationalitiesNo: '11 nationalities',
      },
    },
  },
  values: {
    title: 'Our values',
    body: [
      {
        title: 'Flexibility',
        text: 'We offer our employees a hybrid working arrangement and flexible working hours, enabling them to achieve a perfect work&#x2011;life balance.',
      },
      {
        title: 'Transparency',
        text: 'Being transparent is a core value at Vadis. We extend this transparency to our customers and employees alike, fostering trust and understanding.',
      },
      {
        title: 'Trust',
        text: 'We have full confidence in the way our&nbsp;employees&nbsp;perform&nbsp;their&nbsp;work. Everyone is free to organise themselves as they wish, without being constantly supervised. Autonomy is an essential value that we all appreciate.',
      },
    ],
  },
}
