<template>
  <div class="modal-wrapper">
    <div class="modal">
      <button
        class="btn--close-modal"
        @click="closeModal()"
      >
        <i class="icon-cross icon--sm" />
      </button>
      <div />
      <div class="videos-container">
        <template
          v-for="(video, i) in videos"
        >
          <iframe
            v-if="video.video"
            :key="i"
            width="100%"
            height="100%"
            :src="video.video"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </template>
      </div>
    </div>
  </div>
  <div
    class="overlay"
    @click="closeModal()"
  />
</template>

<script>
import modalClose from '@/mixins/close-modal.js'

export default {
  mixins: [modalClose],
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },
}
</script>
