<template>
  <div class="job-offer-box">
    <h4>{{ offer.title }}</h4>
    <ul class="list">
      <li
        v-if="offer.details.contract"
        class="list-item list-item--align-center"
      >
        <i class="icon-file-text2 icon--sm" />
        <span>{{ offer.details.contract }}</span>
      </li>
      <li
        v-if="offer.details.experience"
        class="list-item list-item--align-center"
      >
        <i class="icon-briefcase icon--sm" />
        <span>{{ offer.details.experience }}</span>
      </li>
      <li class="list-item list-item--align-center">
        <i class="icon-location icon--sm" />
        <span>{{ offer.details.where }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
}
</script>
