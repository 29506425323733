<template>
  <section class="section-about">
    <div class="container margin-bottom--md">
      <h3>{{ about.title }}</h3>
    </div>
    <div class="container grid grid--2-cols">
      <div class="about-img-box">
        <figure class="gallery-item">
          <img
            src="../../assets/pictures/jean-francois-ceo-vadis-technologies.jpg"
            class="about-img"
            :alt="about.body.img.alt"
          >
          <figcaption @click="action()">
            <i class="icon-play2" />
            <p>Jean-François Heering CEO</p>
          </figcaption>
        </figure>
      </div>
      <div>
        <template
          v-for="(parr, i) in about.body.text"
          :key="i"
        >
          <p v-html="parr" />
        </template>
      </div>
      <figure class="testimonial">
        <i class="icon-quotes-left testimonial-icon" />
        <blockquote class="testimonial-text">
          <p>{{ about.body.quote }}</p>
        </blockquote>
      </figure>
      <div class="about-text-box">
        <h3>{{ about.body.cta.text }}</h3>
        <router-link
          to="/meet-us"
          class="link link--white"
        >
          {{ about.body.cta.link }}
        </router-link>
      </div>
    </div>
  </section>
  <VideoModal
    v-if="modalIsVisible"
    :src="src"
    @close="hideModal()"
  />
</template>

<script>
import modal from '@/mixins/open-modal.js'
import VideoModal from '@/components/VideoModal.vue'

export default {
  components: {
    VideoModal,
  },
  mixins: [modal],
  props: {
    about: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      src: null,
    }
  },
  methods: {
    action () {
      this.src = 'https://www.youtube.com/embed/9NXyuRxZ67w?si=7OZZAjK50n2nDzkw'
      this.showModal()
    },
  },
}
</script>
