export default {
  jobOffers: {
    subheading: 'Are you our new coleague ?',
    title: 'Feel free to contact us',
  },
  softSkills: {
    title: 'Do our values line up ?',
    body: [
      {
        title: 'Flexibility',
        text: 'We offer our employees a hybrid working arrangement and flexible working hours, enabling them to achieve a perfect work-life balance.',
      },
      {
        title: 'Transparency',
        text: 'Being transparent is a core value at Vadis. We extend this transparency to our customers and employees alike, fostering trust and understanding.',
      },
      {
        title: 'Trust',
        text: 'We have full confidence in the way our employees perform their work. Everyone is free to organise themselves as they wish, without being constantly supervised. Autonomy is an essential value that we all appreciate.',
      },
    ],
  },
}
