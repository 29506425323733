<template>
  <section class="section-hero">
    <div class="hero">
      <div class="hero-text-box">
        <h1>{{ hero.title }}</h1>
        <p
          class="hero-description"
          v-html="hero.body.text"
        />
      </div>
      <div class="hero-img-box">
        <img
          src="../../assets/pictures/photo-de-l-equipe-vadis-technologies.png"
          class="hero-img"
          :alt="hero.body.img.alt"
        >
      </div>
    </div>
    <a
      href="#section-recent-job-offers"
      class="btn btn--full"
    >
      {{ hero.body.button }} &darr;
    </a>
  </section>
</template>

<script>
export default {
  props: {
    hero: {
      type: Object,
      required: true,
    },
  },
}
</script>
