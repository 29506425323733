<template>
  <Gallery
    :gallery="meetUs.gallery"
  />
  <AllVideos
    :videos="meetUs.allvideos"
  />
  <Testimonials
    :testimonials="meetUs.testimonials"
  />
</template>

<script>
import Gallery from '@/components/MeetUs/Gallery.vue'
import AllVideos from '@/components/MeetUs/AllVideos.vue'
import Testimonials from '@/components/MeetUs/Testimonials.vue'
import meetUs from '@/data/meet-us/en.js'

export default {
  components: {
    Gallery,
    AllVideos,
    Testimonials,
  },
  data () {
    return {
      meetUs,
    }
  },
}
</script>
