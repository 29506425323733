<template>
  <section class="section-key-figures">
    <div class="container margin-bottom--md">
      <p class="subheading">
        {{ keyFigures.title }}
      </p>
    </div>
    <div class="container grid grid--flex-start">
      <div class="key-figure flex flex--column flex--center-v">
        <i class="icon-users icon--md" />
        <h4>{{ keyFigures.body.text.employeesNo }}</h4>
      </div>
      <div class="key-figure flex flex--column flex--center-v">
        <i class="icon-intersex icon--md" />
        <h4 v-html="keyFigures.body.text.genderEquality" />
      </div>
      <div class="key-figure flex flex--column flex--center-v">
        <i class="icon-birthday-cake icon--md" />
        <h4>{{ keyFigures.body.text.averageAge }}</h4>
      </div>
      <div class="key-figure flex flex--column flex--center-v">
        <i class="icon-bubbles4 icon--md" />
        <h4>{{ keyFigures.body.text.nationalitiesNo }}</h4>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    keyFigures: {
      type: Object,
      required: true,
    },
  },
}
</script>
