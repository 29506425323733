<template>
  <section class="section-gallery">
    <div class="container">
      <p class="subheading">
        {{ gallery.subheading }}
      </p>
      <h3>{{ gallery.title }}</h3>
    </div>
    <div class="container">
      <div class="gallery margin-bottom--sm">
        <figure
          v-for="pic in gallery.body.imgsInfo"
          :key="pic.id"
          :class="`gallery-item gallery-item--${pic.id}`"
        >
          <img
            :src="require(`../../assets/pictures/${pic.src}`)"
            :alt="pic.alt"
            class="gallery-img"
          >
          <figcaption
            v-if="pic.caption"
            class="gallery-caption flex flex--row flex--center-v"
            @click="actions(pic)"
          >
            <i class="icon-play2" />
            <p><strong>{{ pic.caption }}</strong></p>
          </figcaption>
        </figure>
      </div>
    </div>
  </section>
  <VideoModal
    v-if="modalIsVisible"
    :src="src"
    @close="hideModal()"
  />
</template>

<script>
import modal from '@/mixins/open-modal.js'
import VideoModal from '@/components/VideoModal.vue'

export default {
  components: {
    VideoModal,
  },
  mixins: [modal],
  props: {
    gallery: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      src: null,
    }
  },
  methods: {
    actions (pic) {
      this.src = pic.video
      this.showModal()
    },
  },
}
</script>
