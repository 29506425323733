import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import TechsAndProjects from '@/views/TechsAndProjects.vue'
import MeetUs from '@/views/MeetUs.vue'
import JobOffers from '@/views/JobOffers.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
// import Development from '@/views/Development.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/tech-projects',
    name: 'tech-projects',
    component: TechsAndProjects,
  },
  {
    path: '/meet-us',
    name: 'meet-us',
    component: MeetUs,
  },
  {
    path: '/job-offers',
    name: 'job-offers',
    component: JobOffers,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/development',
    name: 'development',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Development.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return {
      left: 0,
      top: 0,
    }
  },
})

export default router
