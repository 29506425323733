export default {
  copyright: 'Copyright @2023 by Vadis Technologies. <br/> All right reserved.',
  brussels: {
    office: 'Our Brussels office',
    address: 'Gradient Building, 3rd floor <br/> Av. de Tervueren 270, <br/> 1150 Brussels, Belgium',
  },
  paris: {
    office: 'Our Paris office',
    address: 'Le Swave, 19st floor, 3rd floor <br/> 1 Parvis de la Défense, <br/> 92800 Puteaux, France',
  },
}
