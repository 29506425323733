<template>
  <footer class="footer">
    <div>
      <div class="container margin-bottom--md">
        <div class="logo-col flex flex--column">
          <a href="https://www.vadis.com/">
            <img
              class="logo"
              alt="Join Vadis Technologies, a regtech specialist based in Paris and Brussels"
              src="../assets/logo/logo-de-l-entreprise-vadis-technologies.png"
            >
          </a>
          <ul class="list">
            <li>
              <a
                href="https://fr.linkedin.com/company/vadistechnologies"
                target="_blank"
              ><i class="icon-linkedin" /></a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/vadistechnologies"
                target="_blank"
              ><i class="icon-facebook" /></a>
            </li>
            <li>
              <a
                href="https://instagram.com/vadistechnologies"
                target="_blank"
              ><i class="icon-instagram" /></a>
            </li>
          </ul>
        </div>
        <div class="addresses">
          <div class="address-col">
            <p><strong>{{ footer.brussels.office }}</strong></p>
            <address class="contacts flex flex--column">
              <div class="address">
                <p v-html="footer.brussels.address" />
              </div>
              <div class="footer-links">
                <a
                  class="footer-link"
                  href="tel:+32 2 761 92 70"
                >
                  +32 2 761 92 70
                </a>
                <a
                  class="footer-link"
                  href="mailto:info@vadis.com"
                >
                  info@vadis.com
                </a>
              </div>
            </address>
          </div>
          <div class="address-col">
            <p><strong>{{ footer.paris.office }}</strong></p>
            <address class="contacts flex flex--column">
              <div class="address">
                <p v-html="footer.paris.address" />
              </div>
            </address>
          </div>
        </div>
      </div>
      <div class="container">
        <p
          class="copyright"
          v-html="footer.copyright"
        />
        <router-link
          to="/privacy-policy"
          class="footer-link copyright"
        >
          Privacy policy
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import footer from '@/data/footer/en.js'

export default {
  data () {
    return {
      footer,
    }
  },
}
</script>
